/* add tailwind directives */
@tailwind base;
@tailwind components;
@tailwind utilities;


@keyframes slideInFromRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
}

.custom-mix-blend-luminosity {
  @apply mix-blend-luminosity;
}